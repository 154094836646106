import { batch } from "solid-js";

import canadaAdminRpc from "@repo/protobuf/api/canadaAdminRpc";
import { Admin as CanadaAdminPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/admin_pb";
import Case from "@repo/utils/Case";
import Enum from "@repo/utils/Enum";
import UFunction from "@repo/utils/UFunction";
import FeatureStore from "@repo/utils-solid/FeatureStore";

export type Admin = {
  email: string;
  name: string;
  role?: "sysadmin" | "developer" | "customerSupport";
  status: "active" | "disabled";
};

export namespace Admin {
  export type Id = string & {};
}

export namespace AdminCanada {
  export function normalize(admin: CanadaAdminPb) {
    const role = Case.fromConstant(
      Enum.keyOf(CanadaAdminPb.Role, admin.role),
    ).toCamel();
    const status = Case.fromConstant(
      Enum.keyOf(CanadaAdminPb.Status, admin.status),
    ).toCamel();
    if (status === "unknown") throw new Error("Admin with status UNKNOWN");

    return {
      ...admin,
      role: role === "none" ? undefined : role,
      status,
    };
  }
}

const initialState = {
  authenticated: null as boolean | null,
  admin: null as Admin | null,
};

export const { Provider, useAdmin } = FeatureStore.init(
  "Admin",
  initialState,
  ([store, setStore], { withLogger }) => {
    const accessors = {
      authenticated: () => store.authenticated,
    };

    const actions = withLogger("action", {
      googleSsoCallback(credential: string) {
        return canadaAdminRpc.Auth.googleSsoCallback({ credential }).then(
          ({ admin }) => {
            setStore("admin", AdminCanada.normalize(admin!));
            setStore("authenticated", true);
          },
        );
      },
      fetch() {
        return canadaAdminRpc.Auth.showCurrentAdmin({})
          .then(({ admin }) => {
            batch(() => {
              setStore("admin", AdminCanada.normalize(admin!));
              setStore("authenticated", true);
            });
          })
          .catch((e) => {
            setStore("authenticated", false);

            // TODO: UNAUTHENTICATED error code
            if (e.issues?.[0]?.code !== "UNKNOWN") throw e;
          });
      },
      logout: UFunction.todo,
    });

    return { accessors, actions };
  },
);
